```
<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.deletedcol {
  background-color: #ff7f7f;
}
.transmittedcol {
  background-color: #90ee90;
}
table {
  table-layout: fixed;
}
</style>
<template>
  <div class="card card-custom">
    <div class="full-load" v-if="saveTCNFile">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by enrollment no or tcn"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="search()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-1" style="width:50%!important;"></div> -->
      <!-- <div class="col-md-3 mt-7" v-if="dateRageType != 'custom'"> -->
      <!-- <button class="btn btn-outline-info mr-2" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button> -->
      <!-- <download-excel
            class="btn btn-primary"
            :data="enrollments"
            :header="header"
            :worksheet="todays_date"
            name="enrollment-report.xls"
            :fields="json_fields"
          >
            <i class="test-white fa fa-file-excel"></i>Export to csv
          </download-excel> -->
      <!-- </div> -->

      <!-- <hr v-if="AppType" />
      <div
        class="row"
        v-if="enrollments.length > 0 && !getLoadingData && AppType"
      >
        <div class="col-md-12"> -->
      <!-- <download-excel
            class="btn btn-primary ml-3"
            style="float:right"
            :data="appointments"
            :fields="json_fields"
            :worksheet="date_from + ' - ' + date_to"
            name="Appointment List.xls"
          >
            <i class="test-white fa fa-download"></i> Download Excel
          </download-excel> -->
      <!-- <a
            @click="refreshData()"
            href="#"
            class="btn btn-secondary font-weight-bolder"
            style="float: right"
          >
            <i class="text-white fa fa-refresh"></i> Refresh</a
          >
        </div>
      </div> -->
      <hr v-if="enrollments.length > 0 && !getLoadingData" />
      <div class="row" v-if="enrollments.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <v-table :columns="columns">
            <tr
              v-for="(item, index) in enrollments"
              :key="index"
              :class="[
                item.is_deleted ? 'deletedcol' : '',
                !item.is_deleted &&
                (item.transmit_status == '600' ||
                  item.transmit_status == '300' ||
                  ((item.transmit_status == '200' ||
                    item.transmit_status == '500') &&
                    item.service_type == 'fingerprintcard'))
                  ? 'transmittedcol'
                  : ''
              ]"
            >
              <td>
                <h6 style="font-size: 13px; font-weight: 400">
                  {{
                    item.enroll_date != "" && item.enroll_date != null
                      ? item.enroll_date
                      : dateFormat(item.created_at)
                  }}
                </h6>
              </td>
              <td class="text-right">
                {{ item.id }}
              </td>
              <td class="text-right">
                {{ item.appointment_id }}
              </td>
              <td>
                <!-- <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.applicant }}</a
                > -->
                {{
                  item.applicant == null
                    ? item.fname + " " + item.mname + " " + item.lname
                    : item.applicant
                }}
                <!-- {{ item.fname }} {{ item.mname }} {{ item.lname }} -->
              </td>

              <td>
                {{ item.service_name }}
              </td>
              <td>
                {{ item.originating_location_name }}
              </td>
              <td class="text-center">
                {{ item.tcn != "" || item.tcn != null ? item.tcn : "-" }}
              </td>
              <td class="text-center">
                {{ item.print_count }}
              </td>
              <td class="text-center">
                {{ item.resubmit_count }}
              </td>
              <td class="text-center">
                <div>
                  <div v-if="item.service_type == 'fdle'">
                    <div v-if="item.acha_submit_status == '300'" class="d-flex">
                      Submitted

                      <span
                        v-if="
                          item.acha_response &&
                            item.acha_response.IsSuccessful == 'false'
                        "
                        style="color:red; margin-left:2px;"
                      >
                        <i
                          class="las la-exclamation-circle"
                          style="font-size:20px;color:red"
                        ></i
                      ></span>
                      <span
                        v-if="
                          item.acha_response &&
                            item.acha_response.IsSuccessful == 'true'
                        "
                        style="color:red; margin-left:2px;"
                      >
                        <i
                          class="fa fa-check"
                          style="font-size:20px;color:blue"
                        ></i>
                      </span>
                    </div>

                    <div
                      v-else-if="item.acha_submit_status != '300'"
                      class="d-flex"
                    >
                      Not Submitted
                      <span v-if="item.acha_submit_status == '200'">
                        <i
                          class="las la-exclamation-circle"
                          style="font-size:20px;color:red"
                        ></i
                      ></span>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span
                  v-if="
                    (item.transmit_status == '200' ||
                      item.transmit_status == '500') &&
                      item.service_type != 'fingerprintcard'
                  "
                  >Ready To Transmit</span
                >
                <span
                  v-else-if="
                    (item.transmit_status == '200' ||
                      item.transmit_status == '500') &&
                      item.service_type == 'fingerprintcard'
                  "
                  >Final</span
                >

                <span
                  v-else-if="
                    (item.transmit_status == '300' ||
                      item.transmit_status == '600') &&
                      item.service_type == 'fingerprintcard'
                  "
                  >Final</span
                >
                <span
                  v-else-if="
                    (item.transmit_status == '300' ||
                      item.transmit_status == '600') &&
                      item.service_type != 'fingerprintcard'
                  "
                  >Transmitted</span
                >
                <span
                  v-else-if="
                    item.transmit_status == '100' ||
                      item.transmit_status == '400'
                  "
                  >Draft</span
                >

                <span v-else></span>
              </td>
              <td
                v-if="
                  (item.transmit_status == '300' ||
                    item.transmit_status == '600') &&
                    item.service_type != 'fingerprintcard'
                "
              >
                {{
                  (item.transmitted_by == null || item.transmitted_by == "") &&
                  (item.transmit_status == "300" ||
                    item.transmit_status == "600")
                    ? item.transmit_status == "300" ||
                      item.transmit_status == "600"
                      ? item.updated_by_user
                      : ""
                    : item.transmitted_by
                }}
              </td>

              <td v-else></td>

              <td>
                <div
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="downloadFile(item.tcn, item.id)"
                >
                  <i class="fa fa-download ml-1"></i>
                </div>
              </td>
              <!-- <td>
                <a
                  v-if="
                    (item.transmit_status == '200' ||
                      item.transmit_status == '500') &&

!item.is_deleted
                  "
                  href="javascript:void()"
                  @click="transmit(item.id)"
                  class="btn btn-light-info"
                >
                  <i class="fa fa-share"></i>
                </a>
              </td> -->
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="enrollments.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no enrollment(s) found</h4>
        </div>
      </div>

      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_ADMIN_ENROLLMENT,
  DOWNLOAD_ENROLLMENT_FILE
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";

export default {
  components: {
    vTable,

    vDataLoader
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getServices"])
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
    },
    dateFormat(date) {
      if (date == null) {
        return;
      }
      return moment(date).format("MM/DD/YYYY");
    },
    payementStatus(status) {
      if (status == "C") {
        return "Card";
      }
      if (status == "A") {
        return "Abbreviation";
      }
      if (status == "O") {
        return "On-site";
      }
      if (status == "Cancel") {
        return "Cancel";
      }
    },

    apptStatus(status) {
      var s = "";
      switch (status) {
        case "P":
          s = "Pending";
          break;

        case "C":
          s = "Completed";
          break;

        case "U":
          s = "No-Show";
          break;
        case "Cancel":
          s = "Canceled";
          break;
      }
      return s;
    }
  },
  data() {
    return {
      downloadData: [],
      saveTCNFile: false,

      getLoadingData: false,
      columns: [
        {
          name: "date",
          width: "8%",
          label: "Date",
          class: ""
        },
        {
          name: "Enr No",
          width: "5%",
          label: "Enr No.",
          class: ""
        },
        {
          name: "Appointment No",
          width: "5%",
          label: "App No.",
          class: ""
        },
        {
          name: "applicant",
          width: "10%",
          label: "Applicant",
          class: ""
        },
        {
          name: "Service",
          width: "10%",
          label: "Service",
          class: ""
        },
        {
          name: "Location",
          width: "10%",
          label: "Location",
          class: ""
        },
        {
          name: "tcn",
          width: "10%",
          label: "TCN",
          class: "text-center"
        },
        {
          name: "Print Count",
          width: "5%",
          label: "Print Count",
          class: "text-center"
        },
        {
          name: "Resubmit Count",
          width: "7%",
          label: "Resubmit Count",
          class: "text-center"
        },
        {
          name: "AHCA Status",
          width: "10%",
          label: "AHCA Status",
          class: "text-center"
        },
        {
          name: "status",
          width: "8%",
          label: "Status",
          class: ""
        },
        {
          name: "Transmitted by",
          width: "9%",
          label: "Transmitted by",
          class: ""
        },

        {
          name: "Download",
          width: "6%",
          label: "Download",
          class: ""
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Download TCN",
          route: ""
        }
      ],
      enrollments: [],
      selectedAppId: 0,
      enrollmentData: {},
      currentIndex: -1,
      loadingData: false,
      hasData: false,
      msg: "",
      search_text: "",
      key: 0
    };
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },

  methods: {
    search() {
      this.enrollments = [];
      this.getLoadingData = true;
      this.search_text = this.search_text.trim();
      var data = {
        search_text: this.search_text
      };
      this.$store
        .dispatch(FETCH_ADMIN_ENROLLMENT, data)
        .then(res => {
          this.getLoadingData = false;
          this.enrollments = res.data;
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    downloadFile(tcn, id) {
      var data = {
        tcn: tcn,
        id: id
      };
      this.saveTCNFile = true;
      this.loadingMessage = "please wait <br> ...";
      this.$store
        .dispatch(DOWNLOAD_ENROLLMENT_FILE, data)

        .then(response => {
          const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += sliceSize
            ) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
          };
          const blob = b64toBlob(response.data, "");
          // const blobUrl = URL.createObjectURL(blob);

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", tcn + ".eft");
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
          this.saveTCNFile = false;
          this.loadingMessage = "";

          // const url = window.URL.createObjectURL(new Blob([response]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", tcn + ".eft");
          // document.body.appendChild(link);
          // link.click();
          // URL.revokeObjectURL(link.href);
          // document.body.removeChild(link);
          // this.saveTCNFile = false;
          // this.loadingMessage = "";
        })
        .catch(err => {
          this.dataLoading = false;
          this.$toastr.e(err.data.msg);
          this.saveTCNFile = false;
          this.loadingMessage = "";
        });
    }
  }
};
</script>
